import React from "react";

function MyHobbies() {
  return (
    <div>
      <p>
        I love to do a lot of things in my freetime, but i Want to mention a few
        of these. I like to explore, thats why I like to hike. I think being in
        the nature is the most relaxing thing. I already been in many places at
        Kékes and I want to explore more mountains in the future.
      </p>
      <p>
        Sometimes I want to show others how I see the world. So I started
        learning about photography a year ago.
      </p>
    </div>
  );
}

export default MyHobbies;
